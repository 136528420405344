import axios from 'axios';
import { env } from '../configs/EnvironmentConfig'

const keycloakUserAPI = `${env.KC_ENDPOINT_URL}/auth/realms/${env.KC_REALM}/hcp_el_info`;
const mappingCredentialsEndPoint= `${env.DECISIONS_API_ENDPOINT_URL}/Primary/restapi/Flow/e7c1e09d-1c80-11ec-91e0-326dddd3e106`;
const logoutEfront = `https://learn.homecarepulse.com/logout/1`

let _efrontUser = {};
const requestBody = {
  "userid": env.GUESS_ENDPOINT_USER_ID,
  "password": env.GUESS_PWD,
  "outputtype": "RawJson",
};

export const mapTrainingCredentials = async(username, password, token, whoCalledMe) => {
  try{
    
    if (username && password && token) {
      requestBody["JWT"] = token;
      requestBody["UsernameToMap"] = username;
      requestBody["PasswordToMap"] = password;
  
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "text/plain");
      
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: 'follow'
      };
      
      const results = await fetch(mappingCredentialsEndPoint, requestOptions).catch((error) => {
        console.log(`Error Mapping credentials: from ${whoCalledMe}`);
        console.error(error)
      });    
  
      if(results.status === 200 || results.status === 201 ){
        return {
          wasMappedSuccesfully: true,
          responseMessage: "Credentials mapped succesfully"
        };
      } else if(results.status === 400){        
        return {
          wasMappedSuccesfully: false,
          responseMessage: "No active account found with the provided credentials"
        };
      } else if(results.status === 500){
        return {
          wasMappedSuccesfully: false,
          responseMessage: "Error please contact customer support to help you"
        };
      }
    }
  }catch(err){
    console.log("Error mapTrainingCredentials call");
    console.log(err);
    return {
      wasMappedSuccesfully: false,
      responseMessage: "Unexpected error while processing request"
    };
  }
}

export const getEfrontUserInformation = async() => { 
  await setEfrontCallWrapper("getEfrontUserInformation");
  _efrontUser[`efrontLoginUrl`] = await getEfrontLoginUrl(_efrontUser?.domain, _efrontUser?.onetime).catch((error) => console.log(error));
  console.log("_efrontUser");
  console.log(_efrontUser);
  console.log("_efrontUser END");
  return _efrontUser;
}; 

export const getEfrontLoginUrl = async(domain, suffixUrl) => {  
  const eFrontUserUrl = `https://${domain}${suffixUrl}`;
  return (domain && suffixUrl) ? eFrontUserUrl : "";
}; 

export const getRefreshedEfrontLoginUrl = async() => {
  await setEfrontCallWrapper("getRefreshedEfrontLoginUrl");
  return await getEfrontLoginUrl(_efrontUser?.domain, _efrontUser?.onetime).catch((error) => console.log(error));
}

export const redirectUserTab = async(url) => {  
  await window.open(url);
}; 

export const redirectUserSelf = async (url) => {  
  await window.open(url, "_self");
}; 

export const logoutFromTraining = async() => {  
  const response = await axios.get(logoutEfront, {withCredentials: true}).catch((error) => {
    console.log(`Error Loging out from Efront`);
    console.error(error)
  });
  console.log("logout EF");
  console.log(response);
}

const setEfrontCallWrapper = async(whoCalledMe) => {
  const response = await axios.get(keycloakUserAPI, {withCredentials: true}).catch((error) => {
    console.log(`Error Retrieving userInfo: ${whoCalledMe}`);
    console.error(error)
  });
_efrontUser = {...response?.data}
};



const EfrontService = {
  getEfrontLoginUrl,
  getEfrontUserInformation,
  redirectUserTab,
  redirectUserSelf,
  getRefreshedEfrontLoginUrl,
  mapTrainingCredentials,
  logoutFromTraining
};

export default EfrontService;
