// import Keycloak from 'keycloak-js';
import EfrontService from './EfrontService';
import VantageService from './VantageService';
import AccountService from './Experience/SharedServices/AccountService';
import TemporalModificationsService from './Experience/SharedServices/TemporalModificationsService';
import mainNavigationConfig from "../configs/MainNavigationConfig";
import { getCompanyGroupSubNavWithProperConfiguration } from "../configs/ExperienceSubmenus/CompanyGroupSubNavigation";
import { getLocationSubNavWithProperConfiguration } from "../configs/ExperienceSubmenus/DefaultSubNavigation";
import { env } from '../configs/EnvironmentConfig';
import ExpertSupportServiceForAgency from './ExpertSupportService';

let keycloak = {};

const isInTestingMode = env.ENVIROMENT === 'prod' ? false : true;
keycloak[`efrontLoginUrl`] = "";
keycloak[`selectedAgency`] = {};
keycloak[`surveyResultsStartDate`] = null;
keycloak[`surveyResultsEndDate`] = null;
keycloak[`pivotedStartDate`] = null;
keycloak[`pivotedEndDate`] = null;
keycloak[`userExclusiveDateRanges`] = [];
keycloak[`surveyResults`] = null;
keycloak[`userAwsArn`] = null;

let defaultAdminAccount = {
  CompanyId: 1483,
  CompanyName: "Grandma's Loving Care Test",
  GroupId: 0,
  IsCorporate: false,
  IsSelectedAgency: true,
  IsCompanyGroup: false,
  isSubscribedToReviewManager: true
}

/**
 * Initializes important variables for the app'
 *
 * @param onAuthenticatedCallback
 * @param intkeycloak
 */
const initKeycloak = async (onAuthenticatedCallback, intkeycloak) => {
  console.log("intkeycloak", intkeycloak);

  if (intkeycloak) {
    keycloak = intkeycloak; // Assign the passed instance to `keycloak`
    await processUserServices(onAuthenticatedCallback);
  } else {
    console.error("Keycloak instance not provided during initialization.");
    redirectOnError();
  }
};

const processUserServices = async (onAuthenticatedCallback) => {

  if (isInTestingMode) {
    console.log(env.ENVIROMENT);
  }

  try {
    const userData = await retrieveEfrontUserInformation();
    if (isInTestingMode) {
      console.log("User Info");
      console.log(userData);
    }
    // if user is only found on ELearn DB lets redirect
    if (userData?.onlyEL) {
      if ((getServiceFromQueryParamentersCallback()).isUserComingFromEL && !userData?.learner) {
        await setUpCip(userData, (getServiceFromQueryParamentersCallback()).requestedService);
        // Return access! here else on redirection CIP will be displayed
        onAuthenticatedCallback();
      } else {
        if (userData?.learner) {
          // Does not work after redirection maybe on another tab???
          keycloak.logout();
        }
        EfrontService.redirectUserSelf(userData?.efrontLoginUrl);
      }
    } else {
      await setUpCip(userData, null);
      keycloak[`requestedService`] = (getServiceFromQueryParamentersCallback()).requestedService;
      // Return access! here else on redirection CIP will be displayed
      onAuthenticatedCallback();
    }
  } catch (err) {
    console.log("processUserServices");
    console.error(err);
    if (isInTestingMode) {
      alert("Error in processUserServices in UserService");
    }
  }
};


const setUpCip = async (userData, requestedService) => {
  let services = [];
  await retrieveWebVantageAccounts(keycloak.token, keycloak.tokenParsed?.preferred_username, keycloak.tokenParsed?.email);
  await setDataWarehouseCompanyGroupIdsForAccounts(keycloak?.vantageAccounts, keycloak.token);  
  await setFlagsForUserAccountServices(userData, services, requestedService, keycloak?.vantageAccounts);
  const isThereMoreThanOneAccount = await isThereMoreThanOneWebAccountAssociatedWithUser(keycloak?.vantageAccounts);

  if (isInTestingMode) {
    console.log("Accounts");
    console.log(keycloak?.vantageAccounts);
    console.log("services");
    console.log(services);
    console.log("tokenParsed")
    console.log(keycloak?.tokenParsed)
  }


  if (!isThereMoreThanOneAccount) {
    await setMenuSettingsBasedOnAvailableServicesForAccount(services, keycloak?.vantageAccounts[0]);
    // Set only account as default account
    keycloak.selectedAgency = keycloak?.vantageAccounts[0];
  } else {
    // TODO: To be worked on to assign later a selected company for now is handled in views
    await setMenuSettingsBasedOnAvailableServicesForAccount(services, keycloak?.vantageAccounts[0]);
  }

  // for admins only TODO: refactor from Redux  
  if (isUserSpecialHCPAdmin()) {
    // Set only account as default account
    keycloak.selectedAgency = keycloak?.vantageAccounts[0];
    if (!keycloak.selectedAgency) {
      keycloak.selectedAgency = defaultAdminAccount;
      await setMenuSettingsBasedOnAvailableServicesForAccount(services, keycloak.selectedAgency);
    }

    if (!keycloak.hasOwnProperty('UserId')) {
      const adminId = await getAdminIdFromUsername(keycloak.token, keycloak.tokenParsed?.preferred_username, keycloak.tokenParsed?.email).catch((err) => {
        console.error("getAdminIdFromUsername in UserService");
        console.error(err);
        if (isInTestingMode) {
          alert("Error in getAdminIdFromUsername in UserService");
        }
      });
      keycloak.selectedAgency[`UserId`] = adminId
    }
  }
}

export const getServiceFromQueryParamentersCallback = () => {
  var urlParams = new URLSearchParams(window.location.search);
  const isUserComingFromEL = urlParams.get('redirectFromEL');
  const requestedService = urlParams.get('service');
  return {
    isUserComingFromEL: isUserComingFromEL,
    requestedService: requestedService
  }
}

export const isUserSpecialHCPAdmin = () => {
  if (hasRole(["hcp_administrator"]) || hasRole(["hcp_customer_support"]) || hasRole(["default-roles-master"])) {
    return true;
  }
  return false;
}

export const setMenuSettingsBasedOnAvailableServicesForAccount = async (services, activeAccountInfo) => {
  // If services array is empty means no services are registered maybe an error because customers at least have 1    
  try {
    if (services?.length > 0) {
      services.forEach(function (subscribedService) {
        mainNavigationConfig.forEach(function (service) {
          if (service.key === subscribedService) {
            // Activate that service
            service.isServiceAvailableForUser = true;
            if (service.key === "experience") {
              service.submenu = activeAccountInfo?.IsCompanyGroup ?
                getCompanyGroupSubNavWithProperConfiguration(activeAccountInfo?.CompanyId, activeAccountInfo?.UserName ?? getUserEmail(), activeAccountInfo?.GroupId, activeAccountInfo?.CompanyGroupIds, activeAccountInfo?.UserType) :
                getLocationSubNavWithProperConfiguration(activeAccountInfo?.CompanyId, activeAccountInfo?.UserName ?? getUserEmail(), activeAccountInfo?.GroupId, activeAccountInfo?.CompanyGroupIds, activeAccountInfo?.UserType);
              service.isSubmenuCorpType = activeAccountInfo?.IsCompanyGroup ? true : false;

              if (activeAccountInfo?.IsCompanyGroup) {
                service.path = service.path.concat("-corp");
              }
            }
          }
        });
      });
    }
  } catch (err) {
    console.error("setMenuSettingsBasedOnAvailableServicesForAccount in UserService");
    console.error(err);
    if (isInTestingMode) {
      alert("Error in setMenuSettingsBasedOnAvailableServicesForAccount in UserService");
    }
  }

}

export const setMenuSettingsBasedOnTypeOfAccount = (activeAccountInfo) => {
  try {
    mainNavigationConfig.forEach(function (service) {
      if (service.key === "experience") {
        console.warn(activeAccountInfo);
        service.isSubmenuCorpType = activeAccountInfo?.IsCompanyGroup ? true : false;
        service.submenu = activeAccountInfo?.IsCompanyGroup ?
          getCompanyGroupSubNavWithProperConfiguration(activeAccountInfo?.CompanyId, activeAccountInfo?.UserName ?? getUserEmail(), activeAccountInfo?.GroupId, activeAccountInfo?.CompanyGroupIds, activeAccountInfo?.UserType) :
          getLocationSubNavWithProperConfiguration(activeAccountInfo?.CompanyId, activeAccountInfo?.UserName ?? getUserEmail(), activeAccountInfo?.GroupId, activeAccountInfo?.CompanyGroupIds, activeAccountInfo?.UserType);
        if (!activeAccountInfo?.IsCompanyGroup && service.path.includes("-corp")) {
          service.path = service.path.replace('-corp', '');
        }

        if (activeAccountInfo?.IsCompanyGroup && (!service.path.includes("-corp"))) {
          service.path = service.path.concat("-corp");
        }
      }
    });

  } catch (err) {
    console.error("setMenuSettingsBasedOnTypeOfAccount in UserService");
    console.error(err);
  }
}

const setFlagsForUserAccountServices = async (userData, services, requestedService, accounts) => {
  keycloak[`requestedService`] = "";
  if (requestedService) {
    keycloak[`requestedService`] = requestedService;
    services.push("training");
  } else {
    if (userData) {
      if (userData?.notEL) {
        services.push("experience");
      } else if (userData?.alsoEL) {
        if (isUserSpecialHCPAdmin()) {
          services.push("experience");
          services.push("training");
        } else {
          if (accounts.length > 0) {
            services.push("experience");
            services.push("training");
          } else {
            services.push("training");
          }
        }
        keycloak.efrontLoginUrl = userData?.efrontLoginUrl;
      } else {
        services.push("experience");
      }
    } else {
      services.push("experience");
    }
  }
}

const retrieveEfrontUserInformation = async () => {
  return await EfrontService.getEfrontUserInformation().catch((err) => {
    console.error("getEfrontUserInformation in UserService");
    console.error(err);
    if (isInTestingMode) {
      alert("Error in retrieveEfrontUserInformation in UserService");
    }
  });
}

const retrieveWebVantageAccounts = async (token, preferred_username, email) => {
  keycloak[`vantageAccounts`] = [];
  if (token && preferred_username) {
    keycloak.vantageAccounts = await getVantageAccountInfo(token, preferred_username, email).catch((err) => {
      console.error("getVantageAccountInfo in UserService");
      console.error(err);
      if (isInTestingMode) {
        alert("Error in retrieveWebVantageAccounts in UserService");
      }
    });
  }
}

const isThereMoreThanOneWebAccountAssociatedWithUser = async (accounts) => {
  if (accounts && accounts?.length > 1) {
    return true;
  }
  return false;
}

const setDataWarehouseCompanyGroupIdsForAccounts = async (accounts, token) => {
  let companyGroupIds = []
  if (accounts && token) {
    let companyGroupIdObjects;
    await accounts?.forEach(async function (account) {
      if(account?.IsCompanyGroup){        
        companyGroupIdObjects = await getCompanyGroupIdsAssociatedToCompanyGroupId(account?.CompanyId, token).catch((err) => {
          console.error("getCompanyGroupIdsAssociatedToCompanyGroupId in UserService");
          console.error(err);
          if (isInTestingMode) {
            alert("Error in getCompanyGroupIdsAssociatedToCompanyGroupId in UserService");
          }
        });  

        companyGroupIds = companyGroupIdObjects?.map(obj => obj?.CompanyGroupId_Corp);
      }else{
        companyGroupIdObjects = await getCompanyGroupIdsAssociatedToCompanyId(account?.CompanyId, token).catch((err) => {
          console.error("setDataWarehouseCompanyGroupIdsForAccounts in UserService");
          console.error(err);
          if (isInTestingMode) {
            alert("Error in setDataWarehouseCompanyGroupIdsForAccounts in UserService");
          }
        });

        companyGroupIds = companyGroupIdObjects?.map(obj => obj?.CompanyGroupId);
      }

      // TODO: Temp to be refactored out once DW has a better view, sorry but not sorry
      if(companyGroupIds && companyGroupIds?.length === 0){
        companyGroupIds = await TemporalModificationsService.getCompiledDWCompanyUUIDByCompanyId(account?.CompanyId);
      }

      account[`CompanyGroupIds`] = companyGroupIds;
    });
  }
}

const getCompanyGroupIdsAssociatedToCompanyId = async (companyId, token) => {
  return await AccountService.getCompanyGroupIdsAssociatedToCompanyId(companyId, token, "UserService").catch((err) => {
    console.error("getCompanyGroupIdsAssociatedToCompanyId in UserService");
    console.error(err);
    if (isInTestingMode) {
      alert("Error in getCompanyGroupIdsAssociatedToCompanyId in UserService");
    }
  });
}

const getCompanyGroupIdsAssociatedToCompanyGroupId = async (companyId, token) => {
  return await AccountService.getCompanyGroupIdsAssociatedToCompanyGroupId(companyId, token, "UserService").catch((err) => {
    console.error("getCompanyGroupIdsAssociatedToCompanyGroupId in UserService");
    console.error(err);
    if (isInTestingMode) {
      alert("Error in getCompanyGroupIdsAssociatedToCompanyGroupId in UserService");
    }
  });
}

const getAdminIdFromUsername = async (token, preferred_username, email) => {
  return await VantageService.getAdminIdFromUsername(token, preferred_username, email, "UserService").catch((err) => {
    console.error("getAdminIdFromUsername in UserService");
    console.error(err);
    if (isInTestingMode) {
      alert("Error in getAdminIdFromUsername in UserService");
    }
  });
}

const getVantageAccountInfo = async (token, preferred_username, email) => {
  return await VantageService.getUserAccountsInfo(token, preferred_username, email, "UserService").catch((err) => {
    console.error("getUserAccountsInfo in UserService");
    console.error(err);
    if (isInTestingMode) {
      alert("Error in getVantageAccountInfo in UserService");
    }
  });
}

const getEfrontUserLoginUrl = async () => {
  await processUserServices().catch((err) => {
    console.error("processUserServices");
    console.error(err);
    if (isInTestingMode) {
      alert("Error in getEfrontUserLoginUrl in UserService");
    }
  });
  return (keycloak?.isEfrontAdmin && keycloak?.efrontLoginUrl) ? keycloak?.efrontLoginUrl : "";
};

const redirectOnError = (url) => {
  if (!isInTestingMode) {
    let app = document.querySelector('#root');
    app.append('Loading...');
    app.classList.add("loading");
    EfrontService.redirectUserSelf(url ? url : "https://www.homecarepulse.com/")
  }
};

const getAwsUserArn = () => keycloak.userAwsArn;

const setAwsUserArn = (arn) => { keycloak.userAwsArn = arn };

const getRequestedService = async () => {
  return getServiceFromQueryParamentersCallback();
};
const getPivotedStartDate = () => keycloak?.pivotedStartDate;

const setPivotedStartDate = (pivotedStartDate) => { keycloak.pivotedStartDate = pivotedStartDate; }

const getPivotedEndDate = () => keycloak?.pivotedEndDate;

const setPivotedEndDate = (pivotedEndDate) => { keycloak.pivotedEndDate = pivotedEndDate; }

const getUserSurveyResults = () => keycloak?.surveyResults;

const setUserSurveyResults = (surveyResults) => { keycloak.surveyResults = surveyResults; }

const getUserCexResults = () => keycloak?.cexResults;

const getUserDncResults = () => keycloak?.dncResults;

const setUserCexResults = (cexResults) => { keycloak.cexResults = cexResults; }

const setUserDncResults = (dncResults) => { keycloak.dncResults = dncResults; }

const getUserExclusiveDateRanges = () => keycloak?.userExclusiveDateRanges;

const setUserExclusiveDateRanges = (dateRanges) => { keycloak.userExclusiveDateRanges = dateRanges?.length > 0 ? [...dateRanges] : []; }

const getSurveyResultsStartDate = () => keycloak?.surveyResultsStartDate;

const setSurveyResultsStartDate = (startDate) => { keycloak.surveyResultsStartDate = startDate; }

const getSurveyResultsEndDate = () => keycloak?.surveyResultsEndDate;

const setSurveyResultsEndDate = (endDate) => { keycloak.surveyResultsEndDate = endDate; }

const getExperienceFlagStatus = () => keycloak?.isExperienceActivated;

const setExperienceFlagStatus = (status) => { keycloak.isExperienceActivated = status; }

const getURL = () => keycloak?.efrontLoginUrl;

const doLogin = keycloak.login;

const doLogout = keycloak.logout;

const getToken = () => keycloak.token;

const isLoggedIn = () => !!keycloak.token;

const updateToken = (successCallback) =>
  keycloak.updateToken(5)
    .then(successCallback)
    .catch(doLogin);

const isEmailVerified = () => keycloak.tokenParsed?.email_verified;

const getUsername = () => keycloak.tokenParsed?.preferred_username;

const getUserEmail = () => keycloak.tokenParsed?.email;

const getGivenName = () => keycloak.tokenParsed?.name;

const getWebAccounts = () => keycloak.vantageAccounts;

const hasRole = (roles) => roles.some((role) => keycloak.hasRealmRole(role));

const setSelectedAgency = (agency) => { keycloak.selectedAgency = agency; }

const getSelectedAgency = () => keycloak.selectedAgency;

const getCallToActionAgent = (selectedAgencyId, selectedService) => {
  let renamedSelectedService = "";
  if (selectedService.toLowerCase() === "experience") {
    renamedSelectedService = "em";
  } else if (selectedService.toLowerCase() === "reputation") {
    renamedSelectedService = "rm";
  } else if (selectedService.toLowerCase() === "training") {
    renamedSelectedService = "el";
  }
  return ExpertSupportServiceForAgency.getSpecificAgentBasedOnCriteria(selectedAgencyId, renamedSelectedService);
};

const UserService = {
  getUserCexResults,
  setUserCexResults,
  getAwsUserArn,
  setAwsUserArn,
  getPivotedStartDate,
  setPivotedStartDate,
  setPivotedEndDate,
  getPivotedEndDate,
  getUserSurveyResults,
  setUserSurveyResults,
  getUserExclusiveDateRanges,
  setUserExclusiveDateRanges,
  getSurveyResultsStartDate,
  setSurveyResultsStartDate,
  getSurveyResultsEndDate,
  setSurveyResultsEndDate,
  getServiceFromQueryParamentersCallback,
  isUserSpecialHCPAdmin,
  isEmailVerified,
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  getUserEmail,
  hasRole,
  getURL,
  getEfrontUserLoginUrl,
  setMenuSettingsBasedOnAvailableServicesForAccount,
  setMenuSettingsBasedOnTypeOfAccount,
  getWebAccounts,
  setSelectedAgency,
  getSelectedAgency,
  getRequestedService,
  getCallToActionAgent,
  setExperienceFlagStatus,
  getExperienceFlagStatus,
  getGivenName,
  getUserDncResults,
  setUserDncResults
};

export default UserService;