import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import UserService from './services/UserService';
import HttpService from './services/HttpService';


const renderApp = (keycloak) => {
    console.log('Rendering app with Keycloak:', keycloak);
    const renderApp = () => ReactDOM.render(<App />, document.getElementById('root'));
    UserService.initKeycloak(renderApp, keycloak);
    HttpService.configure();    
  };
  
  // Function to wait for `keycloakInitPromise` to be defined
  const waitForKeycloakPromise = async () => {
    console.log('Waiting for Keycloak initialization...');
    while (!window.keycloakInitPromise) {
      await new Promise((resolve) => setTimeout(resolve, 100)); // Check every 100ms
    }
    return window.keycloakInitPromise;
  };
  
  // Main initialization
  (async () => {
    try {
      const { authenticated, keycloak } = await waitForKeycloakPromise();
  
      if (authenticated) {
        console.log('Keycloak is authenticated. Rendering the app.');
      } else {
        console.warn('Keycloak is not authenticated. Proceeding without authentication.');
      }
      renderApp(keycloak);
    } catch (error) {
      console.error('Error during Keycloak initialization:', error);
    }
  })();